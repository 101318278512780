import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  Tooltip,
  Pie,
  PieChart,
  Cell,
  Bar,
  Area,
  AreaChart,
  Label,
  YAxis,
  CartesianGrid
} from "recharts";
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

const ChatComponent = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const API = process.env.REACT_APP_IMAGE_URL;
  const token = sessionStorage.getItem("token");
  const admin_id = sessionStorage.getItem("admin_id");
  const [selectedRevMonth, setSelectedRevtaMonth] = useState(new Date().getMonth() + 1);
  const [data, setData] = useState([]);
  const [revData, setRevData] = useState('month');
  const [totalRevenue, setTotalRevenue] = useState([]);
  useEffect(() => {
    
    fetchData();
  }, [token]);
  useEffect(() => {
    fetchTotalRevenue();
  }, [revData, selectedRevMonth]);
  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/dashboard`,
        {admin_id}, // You can pass any data here if needed
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(response.data);
      // setLoading(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error appropriately, e.g., setting an error state or displaying a message
    }
  };
  const fetchTotalRevenue = async () => {
    try {
      let durationData = {};

      if (revData === 'day') {
        durationData = {
          // duration: 'day',
          // day: new Date().toISOString().split('T')[0]
            duration: 'week',
          week: '1'
        };
      } else if (revData === 'week') {
        durationData = {
          duration: 'week',
          week: '1'  // Assuming '1' represents the current week
        };
      } else if (revData === 'month') {
        durationData = {
          // duration: 'month',
          // month: selectedRevMonth  // Current month (1-12)
          
        };
      } else {

      }

      const response = await axios.post(
        `${apiUrl}/getTotalRevenue`,
        {...durationData,admin_id} ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );
      setTotalRevenue(response.data.total_revenue);
      // setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error appropriately
    }
  };
  const transformOrderDetails = (orderDetails) => {
    if (!Array.isArray(orderDetails)) {
      return [{ date: new Date().toLocaleDateString(), total: 0, quantity: 0 }]; // Return an array with 0 values if orderDetails is not defined or not an array
    }

    const result = orderDetails.reduce((acc, order) => {
      const date = new Date(order.created_at).toLocaleDateString();
      const amount = parseFloat(order.amount) || 0; // Handle null amounts

      if (!acc[date]) {
        acc[date] = { date, total: 0, quantity: 0 }; // Start with 0
      }
      acc[date].total += amount;
      acc[date].quantity += order.quantity || 0; // Ensure quantity starts with 0

      return acc;
    }, {});

    const sortedResults = Object.values(result).sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date

    // Ensure the chart starts with a 0 value entry
    if (sortedResults.length === 0 || sortedResults[0].total !== 0) {
      sortedResults.unshift({ date: new Date().toLocaleDateString(), total: 0, quantity: 0 });
    }

    return sortedResults;
  };
  const chartData = transformOrderDetails(totalRevenue.order_details);
  return (
    <div className="col-6 j-payment-color2">
    <div className="s_dashboard-head">
      <div className="d-flex justify-content-between text-white">
        <div className="s_dashboard-left-head  ">
          <h2 className="text-white  sjfs-2">{Number(totalRevenue.total_revenue).toFixed(0)}$</h2>

          <p style={{fontSize: "16px",fontWeight: "400",lineHeight: "24px", textAlign: "left"}}>Ingresos totales</p>
        </div>

        <div className="s_dashboard-right-head">
          <div className="mb-2">
            <select
              id="month-select"
              class="form-select sjfs-14"
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSelectedRevtaMonth(selectedValue);
                // setRevData('month')
                if (selectedValue === "12") {
                  const currentMonth = new Date().getMonth() + 1;
                  setSelectedRevtaMonth(currentMonth); // Set to current month
                  // fetchData(); // Call fetchData to get current month data
                }
              }}
              value={selectedRevMonth}
              style={{fontWeight: '500', lineHeight: '21px'}}
            >
              <option value="1">Mes Enero</option>
              <option value="2">Mes Febrero</option>
              <option value="3">Mes Marzo</option>
              <option value="4">Mes Abril</option>
              <option value="5">Mes Mayo</option>
              <option value="6">Mes Junio</option>
              <option value="7">Mes Julio</option>
              <option value="8">Mes Agosto</option>
              <option value="9">Mes Septiembre</option>
              <option value="10">Mes Octubre </option>
              <option value="11">Mes Noviembre</option>
              <option value="12">Mes Diciembre</option>
            </select>
          </div>
          <div className="text-end">
            <input
              type="radio"
              className="btn-check"
              name="options-base2"
              id="option7"
              autoComplete="off"
              disabled={selectedRevMonth != new Date().getMonth() + 1}
            />
            <label
              className="btn btn-outline-primary j-custom-label sjfs-12"
              htmlFor="option7"
              onClick={() => setRevData('day')}
            >
              Día
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options-base2"
              id="option8"
              autoComplete="off"
              disabled={selectedRevMonth != new Date().getMonth() + 1}
            />
            <label
              className="btn btn-outline-primary j-custom-label sjfs-12"
              htmlFor="option8"
              onClick={() => setRevData('week')}
            >
              Semana
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options-base2"
              id="option9"
              autoComplete="off"
              defaultChecked
              checked= {revData == "month"}
              disabled={selectedRevMonth > new Date().getMonth() + 1}
            />
            <label
              className="btn btn-outline-primary j-custom-label sjfs-12"
              htmlFor="option9"
              onClick={() => {
                setRevData('month'); // Set to current month
                fetchData(); // Call fetchData to get current month data
              }}

            >
              Mes
            </label>
          </div>
        </div>
      </div>
    </div>
    <div className="j-payment-body">

    <ResponsiveContainer width="100%" height={450}>
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id="colorOrder" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor="#1c64f2" stopOpacity={0.4} />
              <stop offset="90%" stopColor="#395692" stopOpacity={0.0} />
            </linearGradient>
            <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor="#16bdca" stopOpacity={0.4} />
              <stop offset="90%" stopColor="#1c506a" stopOpacity={0.0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="rgb(55, 65, 82)" horizontal={true} vertical={false} />
              {console.log("chart data",chartData)}
          <XAxis
            dataKey="date"
            axisLine={false}
            tickFormatter={(date) => {
              const d = new Date(date);
              return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}`;
            }}
            // Adjust ticks and interval for single date
            ticks={chartData.length === 1 ? [chartData[0].date] : undefined} // Show only the single date if there's only one entry
            interval={chartData.length === 1 ? 0 : 'preserveStartEnd'} // Preserve start and end if there's more than one date
            domain={chartData.length === 1 ? ['dataMin', 'dataMax'] : ['dataMin', 'dataMax']} // Ensure the domain covers the full range
          />
          <Tooltip cursor={false} formatter={(value, name) => [value, name === 'total' ? 'Total' : 'Quantity']} />
          <Area dataKey="total" stroke="#1c64f2" strokeWidth={3} fill="url(#colorOrder)" dot={false} yAxisId="left" />
          <Area dataKey="quantity" stroke="#16bdca" strokeWidth={3} fill="url(#colorTotal)" dot={false} yAxisId="right" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
    <div className="j-foot-text text-end" >
      <button className="sjfs-14">
        Ver reporte 
      </button>
    </div>
  </div>
  );
};

export default ChatComponent;